import React from 'react'
import AppContext from 'AppContext'

const DeactivateButton = ({ code, membershipId, validTo, keyType, onClick }) => {
  let env = 'staging'
  if (/.*admin.evofitness.*/.test(window.location.origin)) {
    env = 'production'
  }

  const token = window.localStorage.getItem('auth-token')

  const { operator: { id } } = React.useContext(AppContext)

  const now = new Date().toISOString()
  const isDisabled = (validTo === null ? null : (validTo < now ? true : null))

  // We may have to wait for AppContext to be available
  if (!id) {
    return null
  }

  return (
    <div>
      <cl-deactivate-chip
        membershipid={membershipId}
        code={code}
        keytype={keyType}
        apihost={`https://memberships.${env}.credlock.net`}
        operatorid={id}
        token={token}
        isdisabled={isDisabled}
        onClick={onClick}
      />
    </div>
  )
}

export default DeactivateButton
