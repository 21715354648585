import React, { Component } from 'react'
import Table from 'components/Table'
import { dateToLocale, timeToLocale, reactStateToParams } from 'components/Helpers'
import KeysAPI from 'services/api/keys'
import { KeyStatusBadge } from 'components/Badges'
import DeactivateButton from './DeactivateButton'
import SyncUserKeys from './Sync'

class Keys extends Component {
  constructor (props) {
    super(props)

    this.state = {
      log: [],
      operatorID: this.props.match.params.operatorID,
      memberID: this.props.match.params.memberID,
      pages: -1
    }
  }

  render () {
    return (
      <div className='index'>
        <SyncUserKeys
          operatatorid={this.state.operatorID}
          membershipid={this.state.memberID}
        />
        <div className='content-box log'>
          <Table
            data={this.state.log}
            columns={this._columns()}
            pages={this.state.pages}
            serverSidePagination
            loading={this.state.loading}
            onFetchData={this._fetchKeys}
          />
        </div>
      </div>
    )
  }

  _columns = () => (
    [
      {
        Header: 'Type',
        accessor: 'type',
        Cell: props => {
          return (
            <KeyStatusBadge
              validTo={props.original.valid_to}
              txt={props.value.toUpperCase()}
            />
          )
        }
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        Cell: props =>
          timeToLocale(props.value, this.props.timezone)
      },
      {
        Header: 'Copied to door',
        accessor: 'copied_to_door',
        Cell: props => timeToLocale(props.value, this.props.timezone)
      },
      {
        Header: 'Code',
        accessor: 'code'
      },
      {
        Header: 'Valid from',
        accessor: 'valid_from',
        Cell: props => dateToLocale(props.value)
      },
      {
        Header: 'Valid to',
        accessor: 'valid_to',
        Cell: props => dateToLocale(props.value)
      },
      {
        accessor: 'valid_to',
        Cell: props => {
          return (
            <DeactivateButton
              membershipId={this.state.memberID}
              keyType={props.original.type}
              code={props.original.code}
              validTo={props.original.valid_to}
              onClick={() => {
                setTimeout(() => this._fetchKeys(this.state), 2000)
              }}
            />
          )
        }
      },
    ]
  )

  _fetchKeys = (state, instance) => {
    const { operatorID, memberID } = this.state
    this.setState({ loading: true })
    const params = reactStateToParams(state)
    KeysAPI.fetchLog(operatorID, memberID, params, (res) => {
      this.setState({ log: res.data })
      this.setState({ pages: Math.ceil(res.records / state.pageSize) })
      this.setState({ loading: false })
    })
  }
}

export default Keys
